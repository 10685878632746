<template>
  <v-row>
    <v-col :dir="Dir" cols="12">
      <v-card :id="'message-' + row.id" :ref="row.id" outlined class="rounded-xl ml-auto mb-2" max-width="350px" color="rgb(236 130 64)" dark>
        <v-list-item three-line>
          <v-list-item-content>
            <div v-if="row.message" :class="(row.media ? 'mb-4' : 'mb-0')">
              <b style="color: #ffffff;">{{ row.message }}</b>
            </div>
            <div v-if="row.media" class="mb-4">
              <a :href="row.media.mediaUri" target="_blank">
                <span v-if="['image/jpeg','image/jpg','image/png'].includes(row.media.contentType)">
                  <img style="object-fit: contain;" height="180px" width="100%" class="mx-auto" :src="row.media.mediaUri" alt=""/>
                </span>
                <span v-else>Download</span>
              </a>
            </div>
            <v-list-item-subtitle style="color: #ffffff;" :class="(Dir === 'ltr' ? 'text-right' : 'text-left')">
              {{ $moment(row.created_at).format('lll') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AgentMessage",
  props: {
    row: {
      type: Object,
      default: null,
    }
  },
  computed:{
    Dir() {
      if(this.row && this.checkUTF8(this.row.message)){
        return 'rtl';
      }else{
        return 'ltr'
      }
    },
  },
  methods: {
    checkUTF8(text) {
      const regExp = /[\u0600-\u06FF]/;
      return regExp.test(text)
    },
  }
}
</script>

<style scoped>

</style>
